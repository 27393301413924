<template>
    <v-main>
        <div class="px-5">
            <v-toolbar flat dense dark class="primary">
                <v-toolbar-title>
                    Archivos
                </v-toolbar-title>
                <v-spacer/>
                <v-btn text @click="dialogNuevoDoc=true">
                    <v-icon>
                        mdi-plus
                    </v-icon>
                </v-btn>
            </v-toolbar>
            <v-data-table
                elevation="2"
                :headers="headers"
                :items="datosTabla"
                disable-sort
                dense
                :footer-props="{
                    'items-per-page-options': [10, 20, 30, 40, 50]
                }"
                item-key="id"
                :loading="loadingDatosTodos"

                :server-items-length='totalRegistros'
                :options.sync="optionsTable"
            >
                <template v-slot:[`item.descarga`]="{ item }">
                    <div>
                        <span class="text-caption">/{{item.tipoDocumento}}/{{item.archivo}}</span>
                        <v-icon @click="bajaArchivo(item)">mdi-download</v-icon>
                    </div>
                </template>

                <template v-slot:[`item.detalles`]="{ item }">
                    <v-btn icon @click="cargaDetalles(item)">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>

            </v-data-table>

            <nuevo-archivo
                :dialogNuevoDoc.sync="dialogNuevoDoc"
                @guardado="listArchivos"
            />

            <detalle-archivo
                :dialogDetalles.sync="abreDetalleModal"
                :datos="esteArchivo"
            />

        </div>
    </v-main>
</template>

<script>
export default {

    components:{
        'nuevo-archivo':()=>import('@/views/admin/archivos/NuevoArchivo'),
        'detalle-archivo':()=>import('@/views/admin/archivos/DetalleArchivo'),
    },

    data:()=>({
        datosTabla:[],
        headers:[
            {text:'Tipo de archivo', value:'tipoDocumentoNombre', width:"15%"},
            {text:'Descripción', value:'nombreDocumento'},
            {text:'Archivo', value:'descarga', width:"20%"},
            {text:'', value:'detalles', width:"5%"},
        ],
        loadingDatosTodos:false,
        totalRegistros:0,
        optionsTable:{},

        dialogNuevoDoc:false,

        params:{},

        esteArchivo:{},
        abreDetalleModal:false,

    }),

    methods:{
        async bajaArchivo(itm){
            let url = this.$baseUrl+'admin/getArchivo/?id='+itm.archivo
            window.open(url,'winname','directories=no,titlebar=no,toolbar=no,location=0,status=no,menubar=no,scrollbars=no,resizable=no,width=800,height=600')
        },

        cargaDetalles(itm){
            this.esteArchivo=itm
            this.abreDetalleModal=true
        },

        async listArchivos(){

            this.loadingDatosTodos=true

            this.params.start = (this.optionsTable.page-1 )*this.optionsTable.itemsPerPage,
            this.params.limit = this.optionsTable.itemsPerPage
            //this.params.query = this.query
            try{
                let req = await this.$http({
                    url:'/admin/listArchivos',
                    method:'GET'

                })
                this.loadingDatosTodos=false
                this.datosTabla=req.data.root
                this.totalRegistros=req.data.total
            }catch(err){
                this.loadingDatosTodos=false
                this.$store.dispatch('security/revisaError',err)
            }
        },
    },

    watch:{
        optionsTable: {
            handler(){
                this.listArchivos()
            },
            deep: true
        }
    },
}
</script>

<style>

</style>